<template>
  <v-app class="logged-in-app-layout">
    <app-header>
      <template v-slot:append>
        <news-btn v-model="showNews" />
        <help-menu />
      </template>
    </app-header>
    <app-drawer class="app-drawer" />
    <v-main>
      <v-divider class="top-border" />
      <feature-banner />
      <slot />
    </v-main>
    <news-drawer v-model="showNews" />
    <purchase-order-legal-notice />
    <order-action-dialog />
    <global-snackbar />
    <dialog-view-email-detail />
    <global-email-viewer v-if="isStaff" />
    <global-pin v-if="isStaff" />
  </v-app>
</template>

<script setup lang="ts">
import AppDrawer from '~/components/app-drawer.vue'
import AppHeader from '~/components/app-header.vue'
import DialogViewEmailDetail from '@/components/admin/emails/dialog-view-email-detail.vue'
import FeatureBanner from '~/components/feature-banner.vue'
import GlobalEmailViewer from '~/components/admin/global-email-viewer.vue'
import GlobalPin from '~/components/admin/global-pin.vue'
import GlobalSnackbar from '~/components/global-snackbar.vue'
import HelpMenu from '~/components/help-menu.vue'
import NewsBtn from '~/components/news/news-btn.vue'
import NewsDrawer from '~/components/news/news-drawer.vue'
import OrderActionDialog from '~/components/admin/order-action-dialog.vue'
import PurchaseOrderLegalNotice from '~/components/purchase-order-legal-notice.vue'

const authStore = useAuthStore()
const isStaff = authStore.isStaff

const { $msal } = useNuxtApp()
const router = useRouter()

callOnce(() => {
  const account = $msal.getAllAccounts()[0]

  if (account) {
    $msal.acquireTokenSilent({ account, scopes: ['user.read'] })
      .catch(error => {
        console.error(error)
        router.push({ name: 'login' })
      })
  }
})

const showNews = ref(false)
</script>

<style lang="scss" scoped>
$top-border-z: 4;

.app-drawer {
  z-index: $top-border-z + 1 !important;
}

.top-border {
  position: fixed;
  width: 100%;
  z-index: $top-border-z;
}
</style>
