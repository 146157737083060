<template>
  <drawer v-bind="$attrs" v-model="modelValue" no-footer width="500">
    <template v-slot:title>
      <tips-icon class="mr-2" />
      What's New
    </template>
    <div class="pa-6">
      <news-entry
        v-for="entry in entries"
        :key="isoDate(entry.date)"
        v-bind="entry"
        @click="modelValue = false"
      />
    </div>
  </drawer>
</template>

<script setup lang="ts">
import { DateTime } from '@quotetome/materials-api'
import Drawer from '@/components/drawer.vue'
import NewsEntry from '@/components/news/news-entry.vue'
import TipsIcon from '@/components/news/tips-icon.vue'
import { newNews } from './news-store'
import { useAuthStore } from '@/stores/auth'
import { useStorage } from '@vueuse/core'
import { isoDate } from '@/models/filters'

const modelValue = defineModel<boolean>({ default: false })

const authStore = useAuthStore()
const userCompany = authStore.userCompany!

const entries = [
  {
    content: "Manage your job's BOM from your mobile app—add materials, track orders, and collaborate in real time."
      + ' No computer needed on-site. Update your app to get started!',
    date: new DateTime('2025-03-31'),
    images: ['/news/bom-mobile-1.png', '/news/bom-mobile-2.png'],
    mobile: true,
    title: '📱 Bill of Materials is now available on mobile!',
  },
  {
    content: 'Easily review your company suppliers. '
      + 'Edit or add preferred contacts to keep procurement running smoothly.',
    date: new DateTime('2025-02-18'),
    images: ['/news/suppliers-page.png'],
    link: {
      text: 'Explore Suppliers',
      to: { name: 'companies-id-suppliers', params: { id: userCompany.id } }
    },
    title: 'Introducing the new Suppliers Page',
  },
]

const dateSeen = useStorage<string | null>('news-date-seen', null)

onMounted(() => {
  setTimeout(() => {
    const latestDate = entries[0].date

    if (!dateSeen.value || new DateTime(dateSeen.value).isBefore(latestDate)) {
      newNews.value = true
    }
  }, 2000)
})

watch(modelValue, () => {
  if (modelValue.value && newNews.value) {
    dateSeen.value = isoDate(entries[0].date)
    newNews.value = false
  }
})
</script>
