<template>
  <span>
    <v-menu
      v-model="showWidget"
      content-class="qtm-border"
      :close-on-content-click="false"
      :disabled="!sortedEmails.length"
    >
      <template v-slot:activator="{ props }">
        <v-badge
          color="primary"
          :content="unreadCount"
          offset-x="4"
          offset-y="6"
          :model-value="!!unreadCount"
          data-test="unread-count"
        >
          <v-badge
            color="secondary"
            :content="unassociatedCount"
            offset-x="4"
            offset-y="38"
            :model-value="!!unassociatedCount"
            data-test="unassociated-count"
          >
            <v-badge
              color="interactive"
              :content="bouncedCount"
              offset-x="41"
              offset-y="6"
              :model-value="!!bouncedCount"
              data-test="bounced-count"
            >
              <v-badge
                color="cyan"
                :content="unreadGeneralInboxCount"
                offset-x="41"
                offset-y="38"
                :model-value="!!unreadGeneralInboxCount"
                data-test="unread-general-inbox-count"
              >
                <v-btn
                  v-bind="props"
                  class="email-widget-btn"
                  color="interactive"
                  data-test="widget-icon"
                  icon="mdi-email"
                  outlined
                />
              </v-badge>
            </v-badge>
          </v-badge>
        </v-badge>
      </template>
      <div class="type-selector">
        <v-btn-toggle v-model="displayType" color="interactive" group mandatory>
          <v-btn value="grouped" size="x-small">
            Grouped
          </v-btn>
          <v-btn value="sorted" size="x-small">
            Sorted
          </v-btn>
        </v-btn-toggle>
      </div>
      <v-card class="unread-emails-menu">
        <div v-if="displayType === 'sorted'">
          <v-list>
            <v-list-item
              v-for="email in sortedEmails"
              :key="email.id"
              class="sorted-email-item"
              @click.stop="openEmailInDialog(email)"
            >
              <template v-slot:prepend>
                <v-tooltip :text="email.type">
                  <template v-slot:activator="{ props }">
                    <v-icon v-bind="props" :color="getEmailIconColor(email)" dark size="small">
                      mdi-email
                    </v-icon>
                  </template>
                </v-tooltip>
              </template>
              <v-list-item-title>
                <span class="truncate" :title="email.subject">{{ email.subject }}</span>
              </v-list-item-title>
              <v-list-item-subtitle>
                <span class="text-caption">To:</span>
                <span class="text-subtitle-2">{{ email.to_addresses }}</span>
              </v-list-item-subtitle>
              <v-list-item-subtitle>
                <span class="text-caption">On: </span>
                <span class="text-subtitle-2">{{ dateTime(email.date_created) }}</span>
              </v-list-item-subtitle>
            </v-list-item>
          </v-list>
        </div>
        <div v-else>
          <div v-if="unreadCount">
            <v-list class="section-header">
              <v-list-item @click="unreadCollapsed = !unreadCollapsed">
                <v-list-item-title class="text-secondary">
                  <v-icon>
                    {{ unreadIcon }}
                  </v-icon>
                  UNREAD EMAILS
                </v-list-item-title>
              </v-list-item>
            </v-list>
            <v-divider />
            <v-list v-if="!unreadCollapsed">
              <div v-for="order in groupedUnreadEmails.keys()" :key="order" class="pl-0">
                <div class="pl-3" data-test="group-header">
                  <span class="text-caption">Received for:</span>
                  <span v-if="order" class="text-subtitle-1">
                    {{ getOrderTitle(order) }}
                  </span>
                  <span v-else class="text-subtitle-1">
                    General inbox (all companies)
                  </span>
                </div>
                <v-list>
                  <v-list-item
                    v-for="email in groupedUnreadEmails.get(order)"
                    :key="email.id"
                    @click.stop="openEmailInDialog(email)"
                  >
                    <template v-slot:prepend>
                      <v-icon color="primary" dark size="small">
                        mdi-email
                      </v-icon>
                    </template>
                    <v-list-item-title data-test="email-subject">
                      <span class="truncate" :title="email.subject">{{ email.subject }}</span>
                    </v-list-item-title>
                    <v-list-item-subtitle data-test="email-from">
                      <span class="text-caption">From: </span>
                      <span class="text-subtitle-2">{{ email.from_address }}</span>
                    </v-list-item-subtitle>
                    <v-list-item-subtitle v-if="email.is_general_inbox" data-test="email-to">
                      <span class="text-caption">To:</span>
                      <span class="text-subtitle-2">{{ email.recipient }}</span>
                    </v-list-item-subtitle>
                    <v-list-item-subtitle>
                      <span class="text-caption">On: </span>
                      <span class="text-subtitle-2">{{ dateTime(email.date_created) }}</span>
                    </v-list-item-subtitle>
                  </v-list-item>
                </v-list>
              </div>
            </v-list>
          </div>
          <div v-if="unreadGeneralInboxCount">
            <v-list class="section-header">
              <v-list-item @click="unreadGeneralInboxCollapsed = !unreadGeneralInboxCollapsed">
                <v-list-item-title class="text-secondary">
                  <v-icon>
                    {{ unreadGeneralInboxIcon }}
                  </v-icon>
                  GENERAL INBOX UNREAD EMAILS
                </v-list-item-title>
              </v-list-item>
            </v-list>
            <v-divider />
            <v-list v-if="!unreadGeneralInboxCollapsed">
              <div v-for="recipient in groupedUnreadGeneralInboxEmails.keys()" :key="recipient" class="pl-0">
                <div class="pl-3">
                  <span class="text-caption">Received for:</span>
                  <span class="text-subtitle-1">{{ recipient }}</span>
                </div>
                <v-list>
                  <v-list-item
                    v-for="email in groupedUnreadGeneralInboxEmails.get(recipient)"
                    :key="email.id"
                    @click.stop="openEmailInDialog(email)"
                  >
                    <template v-slot:prepend>
                      <v-icon color="cyan" dark size="small">
                        mdi-email
                      </v-icon>
                    </template>
                    <v-list-item-title>
                      <span class="truncate" :title="email.subject">{{ email.subject }}</span>
                    </v-list-item-title>
                    <v-list-item-subtitle>
                      <span class="text-caption">From:</span>
                      <span class="text-subtitle-2">{{ email.from_address }}</span>
                    </v-list-item-subtitle>
                    <v-list-item-subtitle>
                      <span class="text-caption">On: </span>
                      <span class="text-subtitle-2">{{ dateTime(email.date_created) }}</span>
                    </v-list-item-subtitle>
                  </v-list-item>
                </v-list>
              </div>
            </v-list>
          </div>
          <div v-if="unassociatedCount">
            <v-list class="section-header">
              <v-list-item @click="unassociatedCollapsed = !unassociatedCollapsed">
                <v-list-item-title class="text-secondary">
                  <v-icon>
                    {{ unassociatedIcon }}
                  </v-icon>
                  UNASSOCIATED EMAILS
                </v-list-item-title>
              </v-list-item>
            </v-list>
            <v-divider />
            <v-list v-if="!unassociatedCollapsed">
              <div v-for="recipient in groupedUnassociatedEmails.keys()" :key="recipient" class="pl-0">
                <div class="pl-3">
                  <span class="text-caption">Received for:</span>
                  <span class="text-subtitle-1">{{ recipient }}</span>
                </div>
                <v-list>
                  <v-list-item
                    v-for="email in groupedUnassociatedEmails.get(recipient)"
                    :key="email.id"
                    @click.stop="showAssociationDialogForEmail(email)"
                  >
                    <template v-slot:prepend>
                      <v-icon color="secondary" dark size="small">
                        mdi-email
                      </v-icon>
                    </template>
                    <v-list-item-title>
                      <span class="truncate" :title="email.subject">{{ email.subject }}</span>
                    </v-list-item-title>
                    <v-list-item-subtitle>
                      <span class="text-caption">From:</span>
                      <span class="text-subtitle-2">{{ email.from_address }}</span>
                    </v-list-item-subtitle>
                    <v-list-item-subtitle>
                      <span class="text-caption">On: </span>
                      <span class="text-subtitle-2">{{ dateTime(email.date_created) }}</span>
                    </v-list-item-subtitle>
                  </v-list-item>
                </v-list>
              </div>
            </v-list>
          </div>
          <div v-if="bouncedCount">
            <v-list class="section-header">
              <v-list-item @click="bouncedCollapsed = !bouncedCollapsed">
                <v-list-item-title class="text-secondary">
                  <v-icon>
                    {{ bouncedIcon }}
                  </v-icon>
                  BOUNCED EMAILS
                </v-list-item-title>
              </v-list-item>
            </v-list>
            <v-divider />
            <v-list v-if="!bouncedCollapsed">
              <v-list-item
                v-for="email in bouncedEmails"
                :key="email.id"
                @click.stop="openEmailInDialog(email)"
              >
                <template v-slot:prepend>
                  <v-icon color="interactive" dark size="small">
                    mdi-email-outline
                  </v-icon>
                </template>
                <v-list-item-title>
                  <span class="truncate" :title="email.subject">{{ email.subject }}</span>
                </v-list-item-title>
                <v-list-item-subtitle>
                  <span class="text-caption">To:</span>
                  <span class="text-subtitle-2">{{ email.to_addresses }}</span>
                </v-list-item-subtitle>
                <v-list-item-subtitle>
                  <span class="text-caption">On: </span>
                  <span class="text-subtitle-2">{{ dateTime(email.date_created) }}</span>
                </v-list-item-subtitle>
              </v-list-item>
            </v-list>
          </div>
        </div>
      </v-card>
    </v-menu>

    <move-email
      v-if="associatingEmail"
      v-model="showAssociationDialog"
      :email="associatingEmail"
      open-order-after-moving
      @close="associatingEmail=null"
    />
  </span>
</template>

<script>
import { mapState, mapActions } from 'pinia'
import { dateTime } from '~/models/filters'
import MoveEmail from '@/components/admin/emails/move-email.vue'

// for email interval, choose something not an exact multiple of order interval (1 min)
// so they don't frequently hit at the same time
const INBOX_REFRESH_INTERVAL = 57 * 1000 // 57 seconds
const FAILURES_REFRESH_INTERVAL = 60 * 1000 * 5 + 500 // 5 minutes, 500 ms

export default {
  name: 'emails-widget',
  components: { MoveEmail },
  setup() {
    const adminStore = useAdminStore()

    return { adminStore }
  },
  data() {
    return {
      associatingEmail: null,
      bouncedCollapsed: false,
      displayType: 'grouped',
      refreshFailureTimer: null,
      refreshInboxTimer: null,
      showWidget: false,
      unassociatedCollapsed: false,
      unreadCollapsed: false,
      unreadGeneralInboxCollapsed: false,
    }
  },
  computed: {
    ...mapState(useAdminStore, {
      allOrders: 'orders',
      unassociatedEmails: 'unassociatedEmails',
      unreadEmails: 'unreadEmails',
      unreadGeneralInboxEmails: 'unreadGeneralInboxEmails',
      bouncedEmails: 'bouncedEmails',
      emailsFetched: 'emailsFetched',
    }),
    unreadOrders() {
      const orderIds = this.unreadEmails.map(email => email.rfq)
      return this.allOrders.filter(order => orderIds.includes(order.id))
    },
    unreadCount() {
      return this.unreadEmails ? this.unreadEmails.length : 0
    },
    unreadGeneralInboxCount() {
      return this.unreadGeneralInboxEmails ? this.unreadGeneralInboxEmails.length : 0
    },
    unreadIcon() {
      return this.unreadCollapsed ? 'mdi-chevron-right' : 'mdi-chevron-down'
    },
    unreadGeneralInboxIcon() {
      return this.unreadGeneralInboxCollapsed ? 'mdi-chevron-right' : 'mdi-chevron-down'
    },
    unassociatedCount() {
      return this.unassociatedEmails ? this.unassociatedEmails.length : 0
    },
    unassociatedIcon() {
      return this.unassociatedCollapsed ? 'mdi-chevron-right' : 'mdi-chevron-down'
    },
    bouncedCount() {
      return this.bouncedEmails ? this.bouncedEmails.length : 0
    },
    bouncedIcon() {
      return this.bouncedCollapsed ? 'mdi-chevron-right' : 'mdi-chevron-down'
    },
    showAssociationDialog() {
      return !!this.associatingEmail
    },
    groupedUnassociatedEmails() {
      return this.groupBy(this.unassociatedEmails, 'recipient')
    },
    groupedUnreadEmails() {
      return this.groupBy(this.unreadEmails, 'rfq')
    },
    groupedUnreadGeneralInboxEmails() {
      return this.groupBy(this.unreadGeneralInboxEmails, 'recipient')
    },
    sortedEmails() {
      const emails = []
      emails.push(...this.unreadEmails.map(email => ({ ...email, type: 'unread' })))
      emails.push(...this.unreadGeneralInboxEmails.map(email => ({ ...email, type: 'unreadGeneralInbox' })))
      emails.push(...this.unassociatedEmails.map(email => ({ ...email, type: 'unassociated' })))
      emails.push(...this.bouncedEmails.map(email => ({ ...email, type: 'bounced' })))
      return emails.sort((a, b) => new Date(a.date_created) - new Date(b.date_created))
    }
  },
  mounted() {
    if (!this.emailsFetched) {
      this.refreshInbox()
      this.refreshFailures()
      this.markEmailsFetched()
    }
    this.refreshInboxTimer = setInterval(this.refreshInbox, INBOX_REFRESH_INTERVAL)
    this.refreshFailureTimer = setInterval(this.refreshFailures, FAILURES_REFRESH_INTERVAL)
  },
  beforeUnmount() {
    clearInterval(this.refreshInboxTimer)
    clearInterval(this.refreshFailureTimer)
  },
  methods: {
    ...mapActions(useAdminStore, ['markEmailsFetched', 'activateEmail', 'activateOrder']),
    dateTime(date) {
      return dateTime(date)
    },
    openEmailInDialog(email) {
      this.showWidget = false

      if (email.rfq) {
        this.activateOrder({ id: email.rfq }, { email: email.id })
      }
      else {
        this.activateEmail(email)
      }
    },
    showAssociationDialogForEmail(email) {
      this.associatingEmail = email
    },
    getOrderTitle(id) {
      const order = this.allOrders.find(checkedOrder => checkedOrder.id === id)
      return order ? `${order.qtm_reference_number}: ${order.reference_name}` : ''
    },
    groupBy(list, field) {
      const map = new Map()
      list.forEach((email) => {
        const key = email[field]
        let array = map.get(key)
        if (!array) {
          array = []
          map.set(key, array)
        }
        array.push(email)
      })
      return map
    },
    async refreshFailures() {
      try {
        const emails = await this.$api.v1.notifications.email.failures()

        this.adminStore.setBouncedEmails(emails)
      }
      catch (error) {
        this.$error.report(error)
      }
    },
    async refreshInbox() {
      try {
        const lists = {
          unassociated: [],
          unread: [],
          unreadGeneralInbox: []
        }
        const emails = await this.$api.v1.notifications.email.getInbox()

        emails.forEach(email => lists[this.getEmailClassification(email)].push(email))

        this.adminStore.setUnassociatedEmails(lists.unassociated)
        this.adminStore.setUnreadEmails(lists.unread)
        this.adminStore.setUnreadGeneralInboxEmails(lists.unreadGeneralInbox)
      }
      catch (error) {
        this.$error.report(error)
      }
    },
    getEmailIconColor(email) {
      if (email.type === 'unread') {
        return 'primary'
      }
      if (email.type === 'unreadGeneralInbox') {
        return 'cyan'
      }
      if (email.type === 'unassociated') {
        return 'secondary'
      }
      return 'interactive'
    },
    getEmailClassification(email) {
      let type
      if (email.email_type === 'inbound') {
        if (!email.rfq && !email.is_general_inbox) {
          type = 'unassociated'
        }
        else if (email.tracking_status === 'delivered') {
          type = email.is_general_inbox ? 'unreadGeneralInbox' : 'unread'
        }
      }
      else if (email.email_type === 'outbound') {
        if (['bounced', 'rejected'].includes(email.tracking_status)) {
          type = 'bounced'
        }
      }
      return type
    },
  }

}
</script>

<style scoped>
  .email-widget-btn {
    border: 1px solid rgb(var(--v-theme-light-grey));
  }
  .unread-emails-menu {
    max-height: 500px;
    overflow-y: auto;
    width: 500px;
  }
  .unread-count {
    color: white;
    font-weight: 600;
    font-size: 25px;
    text-align: right;
    padding-left: 3px;
  }
  .section-header {
    background-color: rgb(var(--v-theme-background));
    font-weight: bold;
    padding: 0;
  }
  .sorted-email-item {
    padding-top: 5px;
    padding-bottom: 5px;
    border-bottom: 1px solid rgb(var(--v-theme-light-grey));
  }
  .type-selector {
    position: absolute;
    right: 0;
    text-align: right;
    top: 0;
    z-index: 1;
  }
</style>
